<template>
  <b-card title="Thread / Question List">
    <b-row class="mb-3 justify-content-between">
      <b-col cols="12" lg="4" class="d-flex align-items-center"> </b-col>
      <b-col
        cols="12"
        lg="4"
        class="d-flex align-items-center justify-content-end"
      >
        <div class="form-group mb-0">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Name"
            v-model="filter.search"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :postQuestion="postQuestion"
      :listReply="listReply"
      :infiniteHandler="infiniteHandler"
      :is-loading="isLoading"
      :get-data="getData"
      :delete-item="deleteItem"
      :formPayload="formPayload"
      :edit-item="editItem"
      :update-item="updateItem"
      :current-page="currentPage"
      :editId="editId"
      :validations="validations"
    />
  </b-card>
</template>

<script>
import Table from "@/components/thread-question/Table.vue";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      page: 1,
      currentPage: 1,
      isLoading: false,
      result: [],
      postQuestion: {},
      formPayload: {},
      listReply: [],
      editId: null,
      filter: {},
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        content: "",
      };
    },
    editItem(item) {
      if (item.uuid != this.editId) {
        this.page = 1;
        this.listReply = [];
      }
      this.editId = item.uuid;
      this.formPayload = {
        content: "",
      };
      // this.getListReply();
      this.$bvModal.show("form-question");
    },
    infiniteHandler($state) {
      let pages = this.page;
      let params = this.editId;
      this.$http
        .get(
          `/api/v1/admin/communication-activities/question/reply/${params}?limit=5&page=${pages}`
        )
        .then((response) => {
          if (response.data.data.items.length) {
            this.page += 1;
            this.listReply.push(...response.data.data.items);
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch((error) => {});
    },
    updateItem() {
      this.isLoading = true;
      this.$http
        .post(
          `/api/v1/admin/communication-activities/question/reply/${this.editId}`,
          this.formPayload
        )
        .then((response) => {
          successNotification(
            this,
            "Success",
            "Thread / Question successfully Replied!"
          );
          this.cleanUpForm();
          this.$bvModal.hide("form-question");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    getData(page) {
      console.log(page, "data page");
      this.isLoading = true;
      this.currentPage = page;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$http
        .get("/api/v1/admin/communication-activities/question/list/all", {
          params: queryParams,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.postQuestion = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Thread / Question?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/post-categories/remove/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Thread / Question successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
