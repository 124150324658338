<template>
  <div>
    <b-table
      striped
      hover
      :items="postQuestionList"
      :fields="fields"
      :busy="isLoading"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(title_post)="{ item }">
        {{ item.relation ? item.relation.title : "" }}
      </template>

      <template v-slot:cell(category)="{ item }">
        {{ item.relation ? item.relation.type : "" }}
      </template>

      <template v-slot:cell(user)="{ item }">
        {{ item.user ? item.user.name : "" }}
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item @click="editItem(item)">
            <feather-icon icon="EditIcon" class="mr-75" />
            Jawab
          </b-dropdown-item>

          <!-- <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item> -->
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      id="form-question"
      centered
      :title="editIdModal != null ? 'Reply Question' : 'Add Question'"
      size="md"
      no-close-on-backdrop
      hide-footer
    >
      <div class="chat-messages">
        <div class="mb-2" v-for="item in listReplyData" :key="item.uuid">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <div class="d-flex align-items-center" style="gap: 10px">
              <img
                :src="
                  item.user.avatar
                    ? item.user.avatar
                    : require(`@/assets/images/icons/no-image.png`)
                "
                class="image_profile"
                :alt="item.user.name"
              />
              <p class="mb-0">{{ item.user.name }}</p>
            </div>
            <p class="mb-0">{{ item.created_at }}</p>
          </div>
          <p class="mb-0">
            {{ item.content }}
          </p>
        </div>
        <infinite-loading
          direction="bottom"
          @infinite="infiniteHandler"
          ref="refInfiniteLoading"
          v-if="editIdModal != null"
          :identifier="editIdModal"
        ></infinite-loading>
      </div>
      <ValidationObserver>
        <div class="form">
          <div class="form-group">
            <label>Jawab:</label>
            <validation-provider
              name="name"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <textarea
                name=""
                id="jawab"
                class="form-control"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                placeholder="Title"
                v-model="formPayloadQuestion.content"
              />
              <small
                v-for="(validation, index) in validationsForm.content"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
        </div>
        <div v-if="isLoading">
          <br />
          <b-spinner class="mb-2" variant="primary" /><br />
        </div>
        <b-button
          v-if="editIdModal == null"
          variant="primary"
          @click="createItem()"
          v-bind:disabled="isLoading"
        >
          Save Question
        </b-button>
        <b-button
          v-else
          variant="primary"
          @click="updateItem()"
          v-bind:disabled="isLoading"
        >
          Submit Reply
        </b-button>
      </ValidationObserver>
    </b-modal>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    InfiniteLoading,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    postQuestion: {
      type: "",
    },
    listReply: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    infiniteHandler: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    postQuestion(value) {
      this.postQuestionList = value;
    },
    listReply(value) {
      this.listReplyData = value;
    },
    formPayload(value) {
      this.formPayloadQuestion = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    editId(value) {
      this.editIdModal = value;
    },
    // filter: {
    //   handler(value) {
    //     this.$emit("filter", value);
    //   },
    //   deep: true,
    // },
  },
  data() {
    return {
      required,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      postQuestionList: [],
      editIdModal: null,
      formPayloadQuestion: {
        content: "",
      },
      listReplyData: [],
      validationsForm: "",
      fields: [
        { key: "no" },
        { key: "created_at" },
        { key: "title_post" },
        { key: "category" },
        { key: "content", label: "question" },
        { key: "user" },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.postQuestionList.length;
    },
  },
};
</script>

<style lang="scss">
.image_profile {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50px;
}
.chat-messages {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
